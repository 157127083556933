<template>
  <div
    class="full"
    :style="`background: #e6f2ff url(${$store.getters.bgUrl}) no-repeat center center;background-size: cover;`"
  >
    <div class="header"></div>

    <div class="box">
      <h1 class="box-title">
        <Logo></Logo>
      </h1>
      <div class="box-main">
        <!-- 微信扫码登录 -->
        <div
          class="wxTab"
          key="wxTab"
          v-if="$store.getters.needWeixin"
          v-show="loginWin === 0"
        >
          <transition name="fade">
            <div id="login_container" v-show="loginWin === 0"></div>
          </transition>
        </div>
        <!-- 账号密码登录 -->
        <div class="userTab" key="userTab" v-show="loginWin === 1">
          <transition name="fade">
            <el-form
              label-width="0"
              @submit.native.prevent
              v-show="loginWin === 1"
              :model="loginData"
              ref="loginForm"
            >
              <el-form-item
                label="帐号"
                prop="email"
                :rules="[
                  { required: true, message: '请输入帐号', trigger: 'blur' }
                ]"
              >
                <el-input
                  placeholder="请输入帐号"
                  ref="emailInput"
                  v-model="loginData.email"
                  size="large"
                  id="email"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="密码"
                prop="password"
                :rules="[
                  { required: true, message: '请输入密码', trigger: 'blur' },
                  { min: 6, message: '密码必须超过6位', trigger: 'blur' }
                ]"
              >
                <el-input
                  type="password"
                  placeholder="请输入密码"
                  v-model="loginData.password"
                  id="password"
                  @keyup.enter.native.prevent="loginInPasswordInput"
                  size="large"
                ></el-input>
              </el-form-item>
              <el-button
                type="primary"
                size="large"
                style="width: 100%"
                @click.prevent="doSubmit"
                >登 录</el-button
              >
            </el-form>
          </transition>
        </div>
        <!-- nfc登录 -->
        <div class="nfcTab" key="nfcTab" v-show="loginWin === 2">
          <transition name="fade">
            <el-form
              label-width="0"
              v-show="loginWin === 2"
              :model="nfcData"
              @submit.native.prevent
              ref="nfcLoginForm"
            >
              <el-form-item
                label
                prop="nfcCode"
                :rules="[
                  { required: true, message: '请输入nfc码', trigger: 'blur' }
                ]"
              >
                <el-input
                  type="password"
                  ref="nfcInput"
                  placeholder="请输入nfc码"
                  v-model="nfcData.nfcCode"
                  @keypress.enter.native.prevent="loginInNfcInput"
                  size="large"
                ></el-input>
              </el-form-item>
              <el-button
                type="primary"
                size="large"
                style="width: 100%"
                @click.prevent="doNfcSubmit"
                >登 录</el-button
              >
            </el-form>
          </transition>
        </div>
      </div>

      <div class="login-select">
        <!-- <div class="login-text">没有帐户? <span @click="goRegister">创建一个 >></span></div> -->
        <div class="login-select-text">
          <div class="line"></div>
          <div class="text">选择登录方式</div>
        </div>
        <div class="login-select-type">
          <div
            class="login-select-type-item"
            v-if="$store.getters.needWeixin"
            @click="changeLoginWin(0)"
          >
            <i class="fa fa-wechat"></i> 微信扫码登录
          </div>
          <div class="login-select-type-item" @click="changeLoginWin(1)">
            <i class="fa fa-user-circle-o"></i> 帐号密码登录
          </div>
          <div
            class="login-select-type-item nfc-select-color"
            @click="changeLoginWin(2)"
          >
            <!-- <i class="fa fa-user-circle-o"></i> nfc登录 -->
            <svg-icon icon-class="nfc"></svg-icon>nfc登录
          </div>
        </div>
      </div>

      <div class="margin-top-20 register" @click="goRegister">点击注册</div>
    </div>
    <div class="footer">
      <template v-if="isDdgold">
        <a
          href="https://beian.mps.gov.cn/#/query/webSearch?code=44190002005733"
          target="_blank"
          class="beian"
        >
          <img
            style="
              width: 20px;
              display: inline-block;
              height: 20px;
              margin-right: 12px;
            "
            :src="beianSrc"
          /><span>粤公网安备 44190002005733号</span>
        </a>
        &nbsp;&nbsp;
        <a
          href="http://beian.miit.gov.cn"
          target="_blank"
          class="beian"
          rel="noreferrer"
          >粤ICP备 20029807号</a
        >
        &nbsp;&nbsp;&nbsp;&nbsp;
      </template>
    </div>
    <wechatQrcode></wechatQrcode>
  </div>
</template>

<script>
import {
  toUrl,
  http,
  parseTime,
  parseQueryString,
  getCookie,
  removeCookie
} from '@/utils'
import '@/assets/js/WxLogin.js'
import Logo from '@/components/logo.vue'
import wechatQrcode from './wechatQrcode.vue'
import { showWarningModal } from '@/utils'
import beianSrc from '@/assets/images/beian.gov.png' // 测试库图片

export default {
  components: {
    wechatQrcode,
    Logo
  },
  data() {
    return {
      loginData: {
        email: '',
        password: ''
      },
      nfcData: {
        nfcCode: ''
      },
      errorMsg: '',
      loginWin: 0,
      beianSrc
    }
  },
  computed: {
    isDdgold() {
      return !window.location.href.includes('hiram.cn')
    },
    isHostCom() {
      return location.host.match(/\.com$/)
    }
    // current() {
    //   const path = this.$route.path
    //   const pathList = path.split('/')
    //   let state = pathList.join('.')
    //   if (state.indexOf('.') === 0) {
    //     state = state.slice(1)
    //   }

    //   const current = this.$store.getters.statemenu.menuSource.filter(item => item.state === state)
    //   return current && current[0]
    // }
  },
  methods: {
    goRegister() {
      this.$router.push({ name: 'Register' })
    },
    changeLoginWin(type) {
      this.loginWin = type
      window.localStorage['loginWinUserShow'] = this.loginWin
      if (type === 1) {
        this.$nextTick(() => {
          this.$refs.emailInput.focus()
        })
      } else if (type === 2) {
        this.$nextTick(() => {
          this.$refs.nfcInput.focus()
        })
      } else {
        this.$nextTick(() => {
          this.$refs.loginForm.clearValidate()
        })
      }
    },
    loginInPasswordInput(e) {
      if (this.loginData.email && this.loginData.password && e && e.target) {
        e.target.blur()
        this.doSubmit()
      }
    },
    loginInNfcInput() {
      //nfc 输入框回车登录
      if (this.nfcData.nfcCode) {
        this.doNfcSubmit()
      }
    },
    doSubmit(e) {
      e && e.preventDefault()
      let dt = new Date()
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          // 调用接口
          http({
            url: this.$store.state.config.login.loginUrl,
            data: {
              email: this.loginData.email,
              password: this.loginData.password,
              date: parseTime(null, '{y}-{m}-{d}')
            }
          }).then((data) => {
            // 清除验证
            this.$refs.loginForm.clearValidate()
            this.$router.push({
              name: 'SiteLogin',
              params: {
                loginData: data
              }
            })
          })
        }
      })
    },
    doNfcSubmit(e) {
      e && e.preventDefault()
      let dt = new Date()
      this.$refs.nfcLoginForm.validate((vaild) => {
        if (vaild) {
          http({
            url: this.$store.state.config.login.loginUrl,
            data: {
              nfcCode: this.nfcData.nfcCode,
              date: parseTime(null, '{y}-{m}-{d}')
            }
          }).then((data) => {
            // 清除验证
            this.$refs.nfcLoginForm.clearValidate()
            this.$router.push({
              name: 'SiteLogin',
              params: {
                loginData: data
              }
            })
          })
        }
      })
    },
    weixinLoginError() {
      showWarningModal('微信扫码登录失败，请重试或使用其他登录方式。')
      removeCookie('wxLoginError')
      setTimeout(() => {
        this.$router.push({
          name: 'UserLogin'
        })
      }, 300)
    },
    weixinSumbit(type) {
      http({
        url: this.$store.state.config.login.loginUrl,
        data: {
          type: type
        }
      })
        .then((data) => {
          // 登录成功后，跳转页面前，删除cookie
          removeCookie('loginType')
          setTimeout(() => {
            // 清除验证
            this.$refs.loginForm.clearValidate()
            this.$router.push({
              name: 'SiteLogin',
              params: {
                loginData: data
              }
            })
          }, 70)
        })
        .catch(() => {
          this.wxFun()
          // 同样的name，它错误也不会跳转
          // this.$router.push({
          //   name: 'UserLogin'
          // })

          // 登录失败，跳回登录页前，删除cookie
          removeCookie('loginType')
          setTimeout(() => {
            this.$router.push('/login/userlogin')
          }, 70)
        })
    },
    wxFun() {
      if (!this.$store.state.config.login) {
        this.$message.error('未登录需返回特定标识')
        return
      }
      // 不支持微信登录的情况
      if (!this.$store.getters.needWeixin) {
        return
      }
      let url = this.$store.state.config.login.weixinUrl + '?v=3'
      let id = 'login_container'
      http({
        url: url,
        config: {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      }).then((res) => {
        let object = Object.assign(id, res.data)
        new WxLogin(object)
      })
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      document.title = '登录 - ' + vm.$store.state.config.title
      vm.loginData.email = ''
      vm.loginData.password = ''
      // http://localhost:8080/index.html#/login/userlogin?type=wxLogin
      let params = parseQueryString()
      console.log('loginType', getCookie('loginType'))
      if (
        (params && params.type === 'wxLogin') ||
        getCookie('loginType') === 'wxLogin'
      ) {
        vm.weixinSumbit('wxLogin')
      } else {
        // 增加微信扫码失败错误提示
        if (
          (params && params.type === 'wxLoginError') ||
          getCookie('loginType') === 'wxLoginError'
        ) {
          vm.weixinLoginError()
        }
        vm.wxFun()
      }
    })
  },
  created() {
    let loginWinUserShow = parseInt(window.localStorage['loginWinUserShow'])
    // 浏览器有缓存
    if (loginWinUserShow && [0, 1, 2].includes(loginWinUserShow)) {
      this.loginWin = loginWinUserShow
    } else {
      // 浏览器没缓存
      // 不支持微信登录的情况
      if (!this.$store.getters.needWeixin) {
        this.loginWin = 1
      }
    }
  },
  mounted() {
    // focus
    if (this.loginWin == 1) {
      this.$refs.emailInput.focus()
    } else if (this.loginWin == 2) {
      this.$refs.nfcInput.focus()
    }
  }
}
</script>

<style scoped>
.register {
  text-decoration: underline;
  cursor: pointer;
}
.full .el-form-item >>> label {
  display: none;
}
</style>
<style lang="less" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s;
}
.fade-enter,.fade-leave /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.full {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  // background: #e6f2ff url("~@/assets/images/bg.jpg") no-repeat center center;
}

.header {
  margin: 120px auto 45px;
  text-align: center;
}

.footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 20px;
  line-height: 20px;
  overflow: visible;
  z-index: 99;
  clear: both;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
  text-align: right;
  color: #fff;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 20px;
}

.box {
  width: 400px;
  margin: 0 auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #eee;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
  text-align: center;
  .box-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.25;
    margin-bottom: 20px;
  }
  .box-main {
    .wxTab {
      overflow: hidden;
      #login_container {
        margin-bottom: -60px;
      }
    }
    .el-input,
    .el-button {
      font-size: 16px;
    }
  }
  .login-select {
    font-size: 14px;
    color: #777777;
    .login-text {
      text-align: right;
      margin-top: 16px;
      span {
        color: #409eff;
        cursor: pointer;
      }
    }
    .login-select-text {
      margin: 30px 0 20px;
      .line {
        border-top: 1px solid #cccccc;
        height: 1px;
      }
      .text {
        margin: auto;
        width: 120px;
        font-size: 14px;
        background-color: #fff;
        margin-top: -10px;
        height: 20px;
      }
    }
    .login-select-type {
      display: flex;
      justify-content: space-around;
      .login-select-type-item {
        font-size: 16px;
        color: #666;
        cursor: pointer;
        &:hover {
          color: #409eff;
        }
      }
    }
  }
  .nfc-select-color svg {
    margin-right: 5px;
  }
  .nfc-select-color svg:hover path {
    fill: #409eff;
  }
}

.beian {
  color: #fff;
}
.beian:hover {
  text-decoration: underline;
}
</style>

