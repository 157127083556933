var render, staticRenderFns
import script from "./component.vue?vue&type=script&lang=js"
export * from "./component.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_css-loader@6.11.0_webpack@5.96.1__lodash@4._7672cb4986b1fad8a5c21ef801b3f8d9/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports